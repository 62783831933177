<template>
    <v-card flat tile>
        <v-tabs v-if="!showEdit" v-model="tab" background-color="transparent">
            <v-tab key="list">
                {{ $t('settings.lang_kitchenmonitor') }}
            </v-tab>

            <v-tab key="add">
                {{ $t('settings.lang_kitchenmonitorCreate') }}
            </v-tab>
            
            <v-tab key="settings">
                {{ $t('settings.lang_settings') }}
            </v-tab>

            <v-tab @click="openKitchenmon()">
                {{ $t('settings.lang_kitchenmonitorOpen') }}
            </v-tab>

            <v-tabs-items touchless v-model="tab">
                <v-tab-item key="list">
                    <Datatable ref="kitchenMonitorDatatable"
                               :api-endpoint="ENDPOINTS.DATATABLES.SETTINGS.KITCHENMONITORS"
                               :datatable-headers="datatableHeaders"
                               :excel-columns="excelColumns"
                               excel-file-name="Küchenmonitor"
                               @editEntry="editKitchenmonitor"
                               @deleteEntry="deleteEntry"
                               @deleteEntries="deleteEntries"
                               show-delete-buttons
                               show-edit-buttons
                               :permissionDelete="this.$store.getters['permissions/checkPermission']('deposit')"
                               :permissionEdit="this.$store.getters['permissions/checkPermission']('deposit')"
                    />
                </v-tab-item>

                <v-tab-item key="add">
                    <v-container fluid>
                        <v-layout wrap>
                            <v-flex md6 xs12>
                                <v-text-field dense outlined type="text" v-model="name"
                                              :label="$t('generic.lang_name')"></v-text-field>
                            </v-flex>

                            <v-flex offset-md1 md5 xs12>
                                <v-text-field dense outlined type="text" v-model="location"
                                              :label="$t('generic.lang_location')"></v-text-field>
                            </v-flex>

                            <v-flex md12>
                                <v-textarea outlined v-model="description"
                                            :label="$t('generic.lang_description')"></v-textarea>
                            </v-flex>

                            <v-flex md12>
                                <v-switch color="primary" v-model="printWithoutCut"
                                          :label="$t('settings.lang_print_all_items_without_cutting')"></v-switch>
                            </v-flex>

                            <v-flex md12>
                                <v-switch color="primary" v-model="printBookedExtras"
                                          :label="$t('settings.lang_kitchenMonPrintGarnish')"></v-switch>
                            </v-flex>

                            <v-flex md6 xs12>
                                <v-select :items="filteredPrinters"
                                          :label="$t('settings.lang_printer')"
                                          item-text="name"
                                          item-value="id"
                                          outlined
                                          clearable
                                          v-model="printerID"
                                ></v-select>
                            </v-flex>

                            <v-flex offset-md1 md5 xs12>
                                <v-text-field
                                    v-model="mealDangerTime"
                                    @keydown="orderWarningKeyDown"
                                    clearable
                                    :label="$t('settings.lang_orderWarning')"
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <v-flex md12>
                                <v-row>
                                    <v-col cols="12">
                                        {{ $t('settings.lang_selectDefaultKitchenmonitorTmpl') }}
                                    </v-col>

                                    <v-col cols="12" class="pa-0 ml-4">
                                        <v-btn-toggle v-model="template" active-class="success">
                                            <v-btn :value="0" class="ma-0">
                                                {{ $t('settings.lang_restaurantLayout') }}
                                            </v-btn>
                                            <v-btn :value="1" class="ma-0">
                                                {{ $t('settings.lang_fastFoodLayout') }}
                                            </v-btn>
                                        </v-btn-toggle>   
                                    </v-col>
                                </v-row>
                            </v-flex>

                            <v-flex md12 class="text-right">
                                <v-btn text color="error">{{ $t('generic.lang_prev') }}</v-btn>
                                <v-btn color="success" :loading="loading2" :disabled="name < 4 || loading2"
                                       @click="createKitchenmonitor">{{ $t('generic.lang_add') }}
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="settings">
                    <KitchenMonitorSettings />
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>

        <v-container v-else>
            <v-layout wrap>
                <v-layout wrap>
                    <v-flex md6 xs12>
                        <v-text-field dense outlined type="text" v-model="name"
                                      :label="$t('generic.lang_name')"></v-text-field>
                    </v-flex>

                    <v-flex offset-md1 md5 xs12>
                        <v-text-field dense outlined type="text" v-model="location"
                                      :label="$t('generic.lang_location')"></v-text-field>
                    </v-flex>

                    <v-flex md12>
                        <v-switch color="primary" v-model="active"
                                  :label="$t('settings.lang_settings_activ')"></v-switch>
                    </v-flex>

                    <v-flex md12>
                        <v-textarea outlined v-model="description" :label="$t('generic.lang_description')"></v-textarea>
                    </v-flex>

                    <v-flex md12>
                        <v-switch color="primary" v-model="printWithoutCut"
                                  :label="$t('settings.lang_print_all_items_without_cutting')"></v-switch>
                    </v-flex>

                    <v-flex md12>
                        <v-switch color="primary" v-model="printBookedExtras"
                                  :label="$t('settings.lang_kitchenMonPrintGarnish')"></v-switch>
                    </v-flex>

                    <v-flex md6 xs12>
                        <v-select :items="filteredPrinters"
                            :label="$t('settings.lang_printer')"
                            item-text="name"
                            item-value="id"
                            outlined
                            clearable
                            v-model="printerID"
                        ></v-select>
                    </v-flex>

                    <v-flex offset-md1 md5 xs12>
                        <v-text-field
                            v-model="mealDangerTime"
                            @keydown="orderWarningKeyDown"
                            clearable
                            :label="$t('settings.lang_orderWarning')"
                            outlined
                        ></v-text-field>
                    </v-flex>

                    <v-flex md12 class="mb-10">
                        <v-row>
                            <v-col cols="12">
                                {{ $t('settings.lang_selectDefaultKitchenmonitorTmpl') }}
                            </v-col>

                            <v-col cols="12" class="pa-0 ml-4">
                                <v-btn-toggle v-model="template" active-class="success">
                                    <v-btn :value="0" class="ma-0">
                                        {{ $t('settings.lang_restaurantLayout') }}
                                    </v-btn>
                                    <v-btn :value="1" class="ma-0">
                                        {{ $t('settings.lang_fastFoodLayout') }}
                                    </v-btn>
                                </v-btn-toggle>   
                            </v-col>
                        </v-row>
                    </v-flex>

                    <v-flex md12>
                        <v-btn text color="error" @click="showEdit = false">{{ $t('generic.lang_prev') }}</v-btn>
                        <v-btn color="success" :loading="loading3" :disabled="name < 4 || loading3"
                               @click="updateKitchenmonitor">
                            {{ $t('generic.lang_save') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-layout>
        </v-container>
    </v-card>
</template>

<script>
import moment from "moment";
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "../../../config";
import mixin from "../../../mixins/KeyboardMixIns";
import Datatable from "@/components/datatable/Datatable";
import {mapState} from "vuex";
import KitchenMonitorSettings from "./KitchenMonitorSettings.vue";

export default {
    name: "Index",

    components: {
        Datatable,
        KitchenMonitorSettings
    },

    mixins: [mixin],

    data() {
        return {
            ENDPOINTS,
            tab: 0,
            loading: false,
            loading2: false,
            loading3: false,

            id: 0,
            name: "",
            location: "",
            description: "",
            active: false,
            printerID: 0,
            printWithoutCut: false,
            printBookedExtras: false,
            template: 0,
            mealDangerTime: "0:00",


            showEdit: false,
            // --- Datatable ---
            selectedRows: [],
            datatableHeaders: [
                {
                    text: 'ID',
                    align: 'left',
                    value: 'id',
                    width: 80,
                    hide: true
                },
                {text: this.$t('settings.lang_monitor'), value: 'name'},
                {text: this.$t('generic.lang_location'), value: 'location'},
                {text: this.$t('generic.lang_description'), value: 'description'},
                {text: '', value: 'status', align: 'right'},
                {text: this.$t('settings.lang_printer'), value: 'printerID', hide: true},
                {text: this.$t('settings.lang_print_all_items_without_cutting'), value: 'printWithoutCut', hide: true},
                {text: this.$t('settings.lang_kitchenMonPrintGarnish'), value: 'printBookedExtras', hide: true},
                {text: '', value: 'template', hide: true},
                {text: '', value: 'mealDangerTime', hide: true},
                {text: '', value: ''},
            ],
            excelColumns: [

                {
                    label: "ID",
                    field: 'id',
                },
                {
                    label: this.$t('settings.lang_monitor'),
                    field: "monitorName",
                },
                {
                    label: this.$t('generic.lang_location'),
                    field: "location",
                },
                {
                    label: this.$t('generic.lang_description'),
                    field: "description",
                },
            ],
            kitchenmonitors: []
        }
    },

    computed: {
        ...mapState(['printer']),
        filteredPrinters() {
            return this.printer.printers.filter((printer) => printer.type === 1 || printer.type === 2);
        }
    },

    watch: {
        showEdit(val) {
            if (!val) {
                this.name = "";
                this.id = "";
                this.description = "";
                this.location = "";
                this.printWithoutCut = false;
                this.printBookedExtras = false;
                this.template = 0;
                this.mealDangerTime = "0:00";
            }
        }
    },

    methods: {
        orderWarningKeyDown(event) {
            if(!isNaN(event.key)) {
                let val = this.mealDangerTime ? this.mealDangerTime.replaceAll(":", "") + event.key : event.key;

                if (Number(val).toString().length <= 2) {
                    this.mealDangerTime = moment(Number(val), "ss").format("m:ss");
                } else {
                    let v = Number(val).toString();
                    this.mealDangerTime = this.insertAtIndex(v, ':', v.length - 2);
                } 
            } else if (event.key === 'Backspace' && this.mealDangerTime) {
                this.mealDangerTime = this.mealDangerTime.slice(0, -1);
            }

            event.preventDefault();
        },
        insertAtIndex(str, substring, index) {
            return str.slice(0, index) + substring + str.slice(index);
        },
        openKitchenmon() {
            let kitchenmonitorURL = process.env.VUE_APP_KITCHENMONITOR_URL

            if (process.env.VUE_APP_HYBRID == "true") {
                if (window.location.hostname != 'localhost' && window.location.hostname != 'devkasse.3pos.de' && window.location.hostname != 'kasse.3pos.de') {
                    kitchenmonitorURL = location.protocol + "//" + window.location.hostname + ":8037";
                }
            }

            window.open(kitchenmonitorURL, '_blank');
        },
        deleteEntry(entry) {
            let id = [];
            id.push(entry.id);
            this.deleteData(id);
        },
        deleteEntries(entries) {
            this.deleteData(entries);
        },
        deleteData(ids) {
            let self = this;
            this.$swal({
                title: this.$t("settings.lang_deleteKitchenmonitorHead"),
                text: this.$t("settings.lang_deleteKitchenmonitorBody"),
                icon: "warning",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                cancelButtonText: this.$t('generic.lang_cancel'),
                confirmButtonText: this.$t('generic.lang_delete'),
                preConfirm: () => {
                    this.axios.post(ENDPOINTS.SETTINGS.KITCHENMONITORS.DELETE, {
                        ids: ids
                    }).then((res) => {
                        if (res.data.success) {
                            Events.$emit("showSnackbar", {
                                message: this.$t("generic.lang_ActionSuccessful"),
                                color: "success",
                            });
                            self.$refs.kitchenMonitorDatatable.getDataFromApi();
                            self.$refs.kitchenMonitorDatatable.resetSelectedRows();
                        } else {
                            Events.$emit("showSnackbar", {
                                message: this.$t("generic.lang_errorOccurred"),
                                color: "error",
                            });
                        }
                    });
                },
                allowOutsideClick: () => !this.$swal.isLoading,
            });
        },
        createKitchenmonitor() {
            this.loading2 = true;

            let mealDangerTime = '0';

            if (this.mealDangerTime) {
                mealDangerTime = moment.duration(`00:${this.mealDangerTime}`).asSeconds();
            }

            this.axios.post(ENDPOINTS.SETTINGS.KITCHENMONITORS.CREATE, {
                name: this.name,
                location: this.location,
                description: this.description,
                printerID: this.printerID,
                printWithoutCut: this.printWithoutCut,
                printBookedExtras: this.printBookedExtras,
                template: this.template,
                mealDangerTime: mealDangerTime,
            }).then((res) => {
                this.loading2 = false;

                if (res.data.success) {
                    this.tab = 0;
                    this.name = "";
                    this.location = "";
                    this.description = "";
                    this.printerID = 0;
                    this.printWithoutCut = false;
                    this.printBookedExtras = false;
                    this.template = 0;
                    this.mealDangerTime = "0:00";

                    this.$refs.kitchenMonitorDatatable.getDataFromApi();
                    this.$refs.kitchenMonitorDatatable.resetSelectedRows();
                }
            }).catch(() => {
                this.loading2 = false;
            });
        },
        editKitchenmonitor(monitor) {
            this.name = monitor.name;
            this.location = monitor.name;
            this.description = monitor.description;
            this.printerID = parseInt(monitor.printerID);
            this.printWithoutCut = parseInt(monitor.printWithoutCut) == 1 ? true : false;
            this.printBookedExtras = parseInt(monitor.printBookedExtras) == 1 ? true : false;
            this.template = parseInt(monitor.template);
            this.mealDangerTime = moment.utc(monitor.mealDangerTime * 1000).format('mm:ss');

            this.active = (parseInt(monitor.status) ? 1 : 0);
            this.id = monitor.id;

            this.showEdit = true;
        },
        updateKitchenmonitor() {
            this.loading3 = true;

            let mealDangerTime = '0';

            if (this.mealDangerTime) {
                mealDangerTime = moment.duration(`00:${this.mealDangerTime}`).asSeconds();
            }

            this.axios.post(ENDPOINTS.SETTINGS.KITCHENMONITORS.UPDATE, {
                id: this.id,
                name: this.name,
                location: this.location,
                description: this.description,
                status: (this.active ? 1 : 0),
                printerID: this.printerID,
                printWithoutCut: this.printWithoutCut,
                printBookedExtras: this.printBookedExtras,
                template: this.template,
                mealDangerTime: mealDangerTime
            }).then((res) => {
                this.loading3 = false;

                if (res.data.success) {
                    this.tab = 0;
                    this.name = "";
                    this.location = "";
                    this.description = "";
                    this.printerID = 0;
                    this.printWithoutCut = false;
                    this.printBookedExtras = false;
                    this.template = 0;
                    this.mealDangerTime = "0:00";
                    this.id = 0;
                    this.showEdit = false;

                    this.$refs.kitchenMonitorDatatable.getDataFromApi();
                    this.$refs.kitchenMonitorDatatable.resetSelectedRows();
                }
            }).catch(() => {
                this.loading3 = false;
            });
        },
    }
}
</script>